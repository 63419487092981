import { requestApi } from './auth.service';

const getAffiliate = async (id) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/affiliates/${id}`,
  });
  return data[0];
};

export { getAffiliate };
