import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as contributionService from '../../services/contributions.service';

export const fetchHouseholdContributions = createAsyncThunk('contributions/fetchContributions', async () =>
  contributionService.getContributionsByHouseholdId()
);

export const fetchSharingRequestContributions = createAsyncThunk(
  'contributions/fetchContributionsSharingRequest',
  async (sharingRequestId) => contributionService.getContributionsBySharingRequestId(sharingRequestId)
);

export const updateHouseholdContribution = createAsyncThunk(
  'contributions/updateContribution',
  async (contribution, { dispatch, getState }) => {
    await contributionService.updateContribution(contribution);
    dispatch(fetchHouseholdContributions());
  }
);

export const createIUAContribution = createAsyncThunk(
  'contributions/createIUAContribution',
  async (data, { getState, rejectWithValue }) => {
    const household = getState().household.record;

    const request = {
      Name: household.Account_Name,
      Household: household.id,
      Bill_Type: 'IUA',
      Type: 'Membership',
      Total_Amount: data.amount,
      Sharing_Request: data.sharingRequestId,
    };

    if (data.isOneTimePayment) {
      request.isOneTimePayment = true;
      if (data.type === 'Card') {
        const fullName = data.nameOnCC.split(' ');
        const [firstName, lastName = ''] = fullName;
        request.oneTimePaymentMethodDetails = {
          ...data,
          firstName,
          lastName,
          isAuthNet: true,
          last4: 'filled_in_on_backend',
          // householdNo: household.Household_No,
        };
      } else {
        request.oneTimePaymentMethodDetails = {
          isAuthNet: false,
          achName: data.nameOnAccount,
          achNickname: data.bankName,
          achAccount: data.accountNum,
          achRouting: data.routingNum,
          last4: data.accountNum.slice(-4),
        };
      }
    }

    return contributionService.createIUAContribution(request);
  }
);

const initialState = {
  contributions: [],
  loading: false,
  error: '',
  iuaPaymentSubmitted: null,
  iuaPaymentFailed: null,
};

const contributionsSlice = createSlice({
  name: 'contributions',
  initialState,
  reducers: {
    resetIUASuccess: (state) => {
      state.iuaPaymentSubmitted = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHouseholdContributions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHouseholdContributions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = '';
        state.contributions = action.payload;
      })
      .addCase(fetchHouseholdContributions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || action.error;
        state.contributions = [];
      })
      .addCase(fetchSharingRequestContributions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSharingRequestContributions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = '';
        state.contributions = action.payload;
      })
      .addCase(fetchSharingRequestContributions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || action.error;
        state.contributions = [];
      })
      .addCase(updateHouseholdContribution.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHouseholdContribution.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
      })
      .addCase(updateHouseholdContribution.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || action.error;
      })
      .addCase(createIUAContribution.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createIUAContribution.fulfilled, (state) => {
        state.loading = false;
        state.iuaPaymentSubmitted = true;
      })
      .addCase(createIUAContribution.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || action.error;
        state.iuaPaymentSubmitted = false;
        state.iuaPaymentFailed = true;
      });
  },
});

export default contributionsSlice.reducer;

export const { resetIUASuccess } = contributionsSlice.actions;
