import preventiveSharingImg from 'assets/img/enrollment/additional-services-icons/preventive-sharing.png';
import rxShareImg from 'assets/img/enrollment/additional-services-icons/rx-share.png';
import primestinImg from 'assets/img/enrollment/additional-services-icons/primestin_care.png';
import { requestApi } from './auth.service';

const getBaseMemberships = async () => {
  try {
    const { data } = await requestApi({
      method: 'get',
      url: `/memberships/base`,
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getBaseMembership = async (id) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/memberships/base/${id}`,
  });
  return data;
};

const getAddons = async () => {
  const { data } = await requestApi({
    method: 'get',
    url: `/memberships/add-ons`,
  });
  const addOnsArr = data;
  const preventiveIndex = addOnsArr.findIndex((addOn) => addOn.Name === 'Preventive Sharing');
  const rxIndex = addOnsArr.findIndex((addOn) => addOn.Name === 'Rx Share');
  const primestinIndex = addOnsArr.findIndex((addOn) => addOn.Name === 'Primestin Care');
  const addOnArr = [
    {
      ...addOnsArr[preventiveIndex],
      img: preventiveSharingImg,
      alt: addOnsArr[preventiveIndex].Name,
    },
    {
      ...addOnsArr[rxIndex],
      img: rxShareImg,
      alt: addOnsArr[rxIndex].Name,
    },
    {
      ...addOnsArr[primestinIndex],
      img: primestinImg,
      alt: addOnsArr[primestinIndex].Name,
    },
  ];
  return [addOnArr];
};

export { getBaseMemberships, getBaseMembership, getAddons };
