/* eslint-disable no-restricted-syntax */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createMember,
  getMemberByCognitoId,
  getMembersByHousehold,
  deleteMemberDuringRegistration,
  updateMemberDuringRegistration,
  getMembersByEmail,
} from '../../services/members.service';
import { addCredentials } from '../../helpers/authHelper';
import { getCurrentUser, setCurrentUser } from '../../helpers/localStorageHelper';

const getFullName = (member) => `${member?.First_Name} ${member?.Last_Name}`;

const fetchPrimaryMember = createAsyncThunk('enrollment/fetchPrimaryMember', async (cognitoId) => {
  const member = await getMemberByCognitoId(cognitoId);
  const { Enrollment_Incomplete } = getCurrentUser();
  addCredentials({ member });
  if (member?.Enrollment_Incomplete !== Enrollment_Incomplete) {
    window.location.href = '/';
  }
  return member;
});

const fetchMembersByEmail = createAsyncThunk('enrollment/fetchMembersByEmail', async (email) =>
  getMembersByEmail(email)
);

const savePrimaryMember = createAsyncThunk('enrollment/savePrimaryMemebr', async (data, { getState, dispatch }) => {
  await updateMemberDuringRegistration(data);
  const cognitoId = getState().authUser.currentUser.Cognito_UID;
  dispatch(fetchPrimaryMember(cognitoId));
});

const fetchHouseholdMembers = createAsyncThunk('enrollment/fetchHouseholdMembers', async (data, { getState }) => {
  const householdId = getState().household.record.id;
  const members = await getMembersByHousehold(householdId);
  const spouse = members.find((m) => m.Relationship_to_Primary_Member === 'Spouse') || null;
  const children = members.filter((m) => m.Relationship_to_Primary_Member === 'Child');
  return { spouse, children };
});

const saveSecondaryMembers = createAsyncThunk(
  'enrollment/saveSecondaryMembers',
  async (data, { getState, dispatch }) => {
    const householdId = getState().household.record.id;
    const spouseInState = getState().enrollment.spouse;
    if (spouseInState !== null && data.spouse === null) {
      await deleteMemberDuringRegistration(spouseInState.id);
    }
    if (spouseInState === null && data.spouse !== null) {
      data.spouse.Account_Name = householdId;
      await createMember(data.spouse);
    }
    if (spouseInState !== null && data.spouse !== null) {
      await updateMemberDuringRegistration(data.spouse);
    }

    const childrenInState = getState().enrollment.children;
    const childrenToCreate = data.children.filter(
      (dataChild) =>
        !dataChild?.id && !childrenInState.map((stateChild) => getFullName(stateChild)).includes(getFullName(dataChild))
    );
    childrenToCreate.forEach((child) => {
      child.Account_Name = householdId;
    });
    const childrenToDelete = childrenInState.filter(
      (stateChild) =>
        !data.children.map((dataChild) => getFullName(dataChild)).includes(getFullName(stateChild)) &&
        !data.children.map((dataChild) => dataChild?.id).includes(stateChild?.id)
    );
    const childrenToUpdate = data.children.filter((dc) => dc.id);

    const createChildrenPromises = childrenToCreate.map((child) => createMember(child));
    await Promise.all(createChildrenPromises);
    const deleteChildrenPromises = childrenToDelete.map((child) => deleteMemberDuringRegistration(child.id));
    await Promise.all(deleteChildrenPromises);
    const childrenToUpdatePromises = childrenToUpdate.map((child) => updateMemberDuringRegistration(child));
    await Promise.all(childrenToUpdatePromises);

    dispatch(fetchHouseholdMembers());
  }
);

const initialState = {
  primaryMember: {},
  primaryMemberLoading: false,
  spouse: null,
  children: [],
  secondaryMembersLoading: false,
  editMode: null,
  members: [],
  loading: false,
  error: null,
};

const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    setEditMode: (state, { payload }) => {
      state.editMode = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrimaryMember.pending, (state) => {
        state.primaryMemberLoading = true;
      })
      .addCase(fetchPrimaryMember.fulfilled, (state, action) => {
        state.primaryMemberLoading = false;
        state.primaryMember = action.payload;
      })
      .addCase(savePrimaryMember.pending, (state) => {
        state.primaryMemberLoading = true;
      })
      .addCase(savePrimaryMember.fulfilled, (state, action) => {
        state.primaryMemberLoading = false;
      })
      .addCase(savePrimaryMember.rejected, (state, action) => {
        state.primaryMemberLoading = false;
        state.error = action.error?.message || action.error;
      })
      .addCase(fetchHouseholdMembers.pending, (state) => {
        state.secondaryMembersLoading = true;
      })
      .addCase(fetchHouseholdMembers.fulfilled, (state, action) => {
        state.secondaryMembersLoading = false;
        state.spouse = action.payload.spouse;
        state.children = action.payload.children;
      })
      .addCase(fetchMembersByEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMembersByEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload;
      })
      .addCase(saveSecondaryMembers.pending, (state) => {
        state.secondaryMembersLoading = true;
      });
  },
});

export { fetchPrimaryMember, savePrimaryMember, fetchHouseholdMembers, saveSecondaryMembers, fetchMembersByEmail };

export const { setEditMode } = enrollmentSlice.actions;
export default enrollmentSlice.reducer;
