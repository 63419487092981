/* eslint-disable camelcase */
import creditCardType from 'credit-card-type';
import { cardNumber } from '../constants/regex';

export const determineNextBillingDate = (contributionDate) => {
  const date = new Date(contributionDate);
  const month = date.toLocaleString('EN-us', { month: 'long' });
  const day = date.getDate();
  return `${month}, ${day}`;
};
export const parseCardType = (value) => {
  const isValidCcFormat = value.match(cardNumber);
  if (isValidCcFormat) {
    return creditCardType(value)[0]?.type || null;
  }
  return null;
};

export const nthNumber = (number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
