/* eslint-disable prefer-template */
import React from 'react';
import { ReactComponent as AchIcon } from 'assets/svg/notifications/achUncolored.svg';
import { ReactComponent as WarningIcon } from 'assets/svg/notifications/warning.svg';
import { ReactComponent as DangerIcon } from 'assets/svg/notifications/danger.svg';
import { ReactComponent as DocIcon } from 'assets/svg/notifications/document.svg';
import { ReactComponent as AmexIcon } from 'assets/svg/notifications/amex.svg';
import { ReactComponent as VisaIcon } from 'assets/svg/notifications/visa.svg';
import { ReactComponent as DiscoverIcon } from 'assets/svg/notifications/discover.svg';
import { ReactComponent as MasterCardIcon } from 'assets/svg/notifications/masterCard.svg';

export const NotificationTypes = {
  IUA_PAYMENT_NEEDED: 'IUA Payment Needed',
  PAYMENT_METHOD_EXPIRED: 'Payment Method Expired',
  PAYMENT_METHOD_NEEDED: 'Payment Method Needed',
  DOCUMENT_NEEDED: 'Document Needed',
  SIGNATURE_NEEDED: 'Signature Needed',
  ESTIMATE_NEEDED: 'Good Faith Estimate Needed',
};

export const NotificationColorMap = {
  danger: '#DC3545',
  warning: '#E88600',
  success: '#28A745',
  info: '#0CA3DD',
};

export const NotificationIconMap = {
  [NotificationTypes.IUA_PAYMENT_NEEDED]: <WarningIcon />,
  [NotificationTypes.PAYMENT_METHOD_NEEDED]: <DangerIcon />,
  [NotificationTypes.SIGNATURE_NEEDED]: <DocIcon />,
  [NotificationTypes.ESTIMATE_NEEDED]: <DocIcon />,
  [NotificationTypes.DOCUMENT_NEEDED]: <DocIcon />,
};

export const PaymentMethodIconMap = {
  visa: <VisaIcon />,
  ach: <AchIcon />,
  americanexpress: <AmexIcon />,
  discover: <DiscoverIcon />,
  mastercard: <MasterCardIcon />,
};

export const NotificationLinkMap = {
  [NotificationTypes.PAYMENT_METHOD_EXPIRED]: '/payment-center',
  [NotificationTypes.PAYMENT_METHOD_NEEDED]: '/payment-center/payment-method',
};
